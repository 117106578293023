import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import TagCloud from '../components/TagCloud';

import Layout from '../components/Layout';
import { MenuItem } from '../components/Post';
// import Search from '../components/Search';

const TagTemplate = ({ location, data, pageContext }) => (
  <Layout
    title={data.site.siteMetadata.title}
    location={location}
    metadata={data.site.siteMetadata}
  >
    <div
      className={'bg-blue text-gold'}
      style={{ width: '100%', minHeight: '100vh', overflow: 'hidden' }}
    >
      <nav className={'menu'}>
        <div className={'menu-item menu-title'}>
          <Img resolutions={data.file.childImageSharp.fixed} />
          <h1 className={'ml-4'}>{data.site.siteMetadata.title}</h1>
          <p className={'ml-4'}>
            about <em>{pageContext.subject}</em>
          </p>
        </div>
        {data.allPloneClassicPage.edges.map((edge, idx) =>
          MenuItem(edge.node, pageContext.duotones[edge.node._path])
        )}
        {/*
        <div className={'menu-search'}>
          <Search />
        </div>
        */}
        <div className={'menu-cloud'}>
          <TagCloud tags={pageContext.subjects} />
        </div>
      </nav>
    </div>
  </Layout>
);

export default TagTemplate;

export const query = graphql`
  query TagTemplateQuery($subject: String!) {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allPloneClassicPage(
      filter: { subjects: { in: [$subject] } }
      limit: 1000
      sort: { order: DESC, fields: [effective] }
    ) {
      edges {
        node {
          _id
          _type
          _path
          title
          image {
            publicURL
            childImageSharp {
              red: fixed(
                height: 800
                duotone: { shadow: "#002957", highlight: "#f1563f", opacity: 0 }
              ) {
                ...GatsbyImageSharpFixed
              }
              yellow: fixed(
                width: 400
                duotone: { shadow: "#002957", highlight: "#caa260", opacity: 0 }
              ) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;
